<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" :title="$t('global.batixsoftware')"></navbar>

    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>

            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4"></div>
              <div class="mr-4">
                <button class="btn btn-success" @click="closeMonth">
                  <font-awesome-icon
                    icon="fa-duotone fa-square-check"
                    class="mr-2"
                  />{{ $t("timereporting.closemonth") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Start aktuelle Stunden Karten -->
        <div class="row">
          <div class="col-xl-9 ml-auto p-0 mt-1 mb-1">
            <div
              v-if="angemeldeterMitarbeiter"
              class="container-fluid tile-container p-0"
            >
              <div
                class="row pt-1 pb-1"
                v-if="berechtigungen.m_timereporting.read"
              >
                <div class="col-lg-12">
                  <b-card-group deck>
                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Feriensaldo"
                            style="font-size: 24px"
                            icon="fa-duotone fa-tree-palm"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                            >{{ zeitkonto.feriensaldo }} h</b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>

                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Gleitzeitsaldo"
                            style="font-size: 24px"
                            icon="fa-duotone fa-house-laptop"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                            >{{ zeitkonto.gleitzeitsaldo }} h</b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>

                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Stunden krank in diesem Jahr"
                            style="font-size: 24px"
                            icon="fa-duotone fa-face-mask"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                            >{{ zeitkonto.kranksaldo }} h</b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>

                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Stunden Zivildienst in diesem Jahr"
                            style="font-size: 24px"
                            icon="fa-duotone fa-hand-holding-hand"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                            >{{ zeitkonto.zivildienstsaldo }} h</b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>

                    <b-card
                      class="text-center"
                      bg-variant="info"
                      text-variant="white"
                      no-body
                    >
                      <b-card-body style="padding: 0.25rem">
                        <h2>
                          <font-awesome-icon
                            v-tooltip.hover
                            title="Stunden Militär in diesem Jahr"
                            style="font-size: 24px"
                            icon="fa-duotone fa-person-rifle"
                            class="mr-2"
                            transform="down-2"
                          />
                          <b-badge variant="light"
                            >{{ zeitkonto.militaersaldo }} h</b-badge
                          >
                        </h2>
                      </b-card-body>
                    </b-card>
                  </b-card-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Ende aktuelle Stunden Karten -->
      </div>

      <div class="col-xl-3 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Daten -->
        <container-headline
          :headline="$t('global.timereporting')"
          :col="4"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row mb-4">
                <div class="col-xl-12">
                  <month-picker
                    @change="showDate"
                    lang="de"
                    :default-month="month"
                    :default-year="year"
                    style="width: 100%; font-size: 12px"
                  ></month-picker>
                </div>
              </div>
              <!--  <div class="row">
                <div class="col-xl-12">
                  <single-select-person
                    id="mitarbeiter"
                    :person="mitarbeiter"
                    :label="$t('global.employee')"
                    :allowOpen="false"
                    editable
                    :rollen="filterMitarbeitende"
                    @confirmed="setMitarbeiter"
                  />
                </div>
              </div> -->
            </div>
          </div>
        </div>

        <!-- ENDE DATEN -->
      </div>

      <div class="col-xl-9 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Daten -->
        <container-headline
          :headline="$t('global.timeoverview')"
          :col="4"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12">
                  <b-table
                    :items="zeiterfassung"
                    :fields="fields"
                    hover
                    :tbody-tr-class="rowClass"
                    @row-dblclicked="details"
                  >
                    <template #cell(status)="data">
                      <font-awesome-icon
                        v-if="data.value === 'closed'"
                        style="color: green"
                        icon="fa-solid fa-lock"
                      />
                      <font-awesome-icon
                        v-if="data.value !== 'closed'"
                        icon="fa-solid fa-unlock"
                      />
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ENDE DATEN -->
      </div>
    </div>

    <messagebox-enhanced
      :headerText="$t('timereporting.closemonth')"
      :ok="true"
      :cancel="true"
      @ok="closeMonthPerform"
    />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";

import { parseDateString } from "@/utils/bxchTools.js";

import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import store from "@/store";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";
//import SingleSelectPerson from "@/components/global/SingleSelectPerson.vue";

export default {
  name: "Zeitübersicht",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    //  SingleSelectPerson,
    MessageboxEnhanced,
  },
  mixins: [page, dashboard],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      overviewData: [],
      state: {
        zeiterfassung: false,
        feiertage: false,
        account: false,
      },
      feiertage: [],
      mitarbeiter: {},
      zeiterfassung: [],
      zeitkonto: {},
      filterMitarbeitende: this.$CONST("ROLLEN").MITARBEITER,
      fields: [
        {
          key: "status",
          label: this.$t("dashboard.status"),
          sortable: false,
          tdClass: "text-center p-0",
          thClass: "text-center",
        },
        {
          key: "datum",
          label: this.$t("dashboard.day"),
          sortable: false,
          tdClass: "text-center p-0",
          thClass: "text-center",
          formatter(value) {
            let nums = value.split(".");
            return nums[0];
          },
        },
        {
          key: "tag.wochentag.bezeichnung",
          label: this.$t("dashboard.weekday"),
          sortable: false,
          tdClass: "text-center p-0",
          thClass: "text-center",
          formatter(value, key, item) {
            if (item.tag.ist_feiertag) {
              return item.tag.feiertag.bezeichnung;
            } else {
              return value.substring(0, 2);
            }
          },
        },
        {
          key: "sollstunden",
          label: this.$t("dashboard.targethours"),
          sortable: false,
          tdClass: "text-center p-0",
          thClass: "text-center",
          formatter(value) {
            return value.toFixed(2);
          },
        },
        {
          key: "stunden",
          label: this.$t("dashboard.workedhours"),
          sortable: false,
          // tdClass: "text-center p-0",
          tdClass(value, key, item) {
            let thisClass = "";
            const nums = item.datum.split(".");
            let dateString = nums[2] + "-" + nums[1] + "-" + nums[0];
            let thisdate = new Date(dateString);
            let today = new Date().getTime();

            if (item.stunden > 0 && item.stunden <= item.sollstunden * 1.1) {
              thisClass = "table-medium-green";
            }
            if (item.stunden > 0 && item.stunden > item.sollstunden * 1.1) {
              thisClass = "table-warning";
            }
            if (item.krank > 0 || item.militaer > 0 || item.zivildienst > 0) {
              thisClass = "table-dark";
            }
            if (
              item.stunden === 0 &&
              item.sollstunden !== 0 &&
              thisdate < today // item.datum muss zu richtigem Datum umgewandelt werden
            ) {
              thisClass = "table-danger";
            }
            return "text-center p-0 " + thisClass;
          },
          thClass: "text-center",
          formatter(value) {
            if (value === 0) {
              return "";
            } else {
              return value.toFixed(2);
            }
          },
          /*     tdClass(value, key, item) {
        let thisClass = ""
        if (item.stunden > 0 && item.stunden <= item.sollstunden * 1.1) {
          thisClass = 'table-medium-green';
        } 
        if (item.stunden > 0 && item.stunden > item.sollstunden * 1.1) {
          thisClass = 'table-warning';
        } 
        if (item.krank > 0 || item.militaer > 0 || item.zivildienst > 0) {
          thisClass = 'table-dark';
        }
        if (item.stunden === 0 && new Intl.DateTimeFormat('de-CH').format(new Date()) > item.datum && item.sollstunden !== 0) {
          thisClass = 'table-danger';
        }
           return 'text-center p-0 ' + thisClass
          }, */
        },
        {
          key: "ferien",
          label: this.$t("dashboard.vacation"),
          sortable: false,
          tdClass: "text-center p-0",
          thClass: "text-center",
          formatter(value) {
            if (value === 0) {
              return "";
            } else {
              return value.toFixed(2);
            }
          },
        },
        {
          key: "krank",
          label: this.$t("dashboard.ill"),
          sortable: false,
          tdClass: "text-center p-0",
          thClass: "text-center",
          formatter(value) {
            if (value === 0) {
              return "";
            } else {
              return value.toFixed(2);
            }
          },
        },
        {
          key: "zivildienst",
          label: this.$t("dashboard.publicservice"),
          sortable: false,
          tdClass: "text-center p-0",
          thClass: "text-center",
          formatter(value) {
            if (value === 0) {
              return "";
            } else {
              return value.toFixed(2);
            }
          },
        },
        {
          key: "militaer",
          label: this.$t("dashboard.army"),
          sortable: false,
          tdClass: "text-center p-0",
          thClass: "text-center",
          formatter(value) {
            if (value === 0) {
              return "";
            } else {
              return value.toFixed(2);
            }
          },
        },
      ],
    };
  },
  watch: {
    state: {
      deep: true,
      handler: function (val) {
        /*  if (val.zeiterfassung && val.feiertage && val.account)
          this.buildOverview(); */
      },
    },
  },
  computed: {
    queryParams: function () {
      var params = {};

      params.ma = this.mitarbeiter?.id;
      params.mo = this.month;
      params.y = this.year;

      return params;
    },

    navbarTitel: function () {
      if (this.id) {
        return `Zeitübersicht`;
      } else {
        return this.$t("global.timeoverview");
      }
    },
  },
  created() {
    this.loading = true;

    this.loadOverview();
  },
  mounted: function () {
    this.setMitarbeiter(this.angemeldeterMitarbeiter.person);

    this.setHeaders("dashboard", [
      "status",
      "day",
      "weekday",
      "targethours",
      "workedhours",
      "vacation",
      "ill",
      "publicservice",
      "army",
    ]);
  },
  methods: {
    /* buildOverview() {
      if (
        this.state.zeiterfassung == false ||
        this.state.feiertage == false ||
        this.state.account == false
      )
        return;

      let workdayhours = this.$CONST("CONFIG").WORKWEEKHOURS / 5;
      let effectiveHours = workdayhours * (this.zeitkonto.stellenprozent / 100);

      let data = [];
      let weekday = [
        "Sonntag",
        "Montag",
        "Dienstag",
        "Mittwoch",
        "Donnerstag",
        "Freitag",
        "Samstag",
      ];

      let daysInMonth = new Date(this.year, this.month, 0).getDate();

      for (let i = 0; i < daysInMonth; i++) {
        let thisDate = new Date(this.year, this.month - 1, i + 1);
        let feiertag = this.feiertage.find(
          (ft) =>
            thisDate.toString().valueOf() ===
            parseDateString(ft.datum).toString().valueOf()
        );

        let dayData = {
          id: this.getHourValue(thisDate, "id"),
          day: thisDate.getDate(),
          date: new Intl.DateTimeFormat("ch-DE").format(thisDate),
          weekday: feiertag ? feiertag.bezeichnung : weekday[thisDate.getDay()],
          hours: (thisDate.getDay() == 0 || thisDate.getDay() == 6
            ? 0
            : feiertag
            ? effectiveHours - (effectiveHours / 100) * feiertag.anteil
            : effectiveHours
          ).toFixed(2),
        };

        if (thisDate.getDay() == 0 || thisDate.getDay() == 6)
          dayData["_rowVariant"] = "secondary";

        if (feiertag && feiertag.anteil === 100)
          dayData["_rowVariant"] = "secondary";

        let status = this.getHourValue(thisDate, "status");
        dayData["status"] = status ? status.appkey : "";

        let workedHours = this.getHourValue(thisDate, "stunden");
        dayData["workedhours"] = this.formatHourValue(workedHours);

        if (dayData != "" && dayData.hours > 0 && workedHours == 0)
          dayData["_cellVariants"] = { workedhours: "danger" };

        if (dayData != "" && workedHours > dayData.hours * 1.1)
          dayData["_cellVariants"] = { workedhours: "warning" };

        dayData["ferien"] = this.formatHourValue(
          this.getHourValue(thisDate, "ferien")
        );
        dayData["krank"] = this.formatHourValue(
          this.getHourValue(thisDate, "krank")
        );
        dayData["zivildienst"] = this.formatHourValue(
          this.getHourValue(thisDate, "zivildienst")
        );
        dayData["militaer"] = this.formatHourValue(
          this.getHourValue(thisDate, "militaer")
        );

        data.push(dayData);
      }
      this.overviewData = data;
    }, */

    rowClass(item, type) {
      if (!item || type !== "row") return;

      let thisClass = "";
      if (item.sollstunden === 0) {
        thisClass = "table-secondary";
      }
      return "item " + thisClass;
    },

    /*  formatHourValue(value) {
      return value === "" ? "" : value.toFixed(2);
    }, */

    /*  getHourValue(date, entry) {
      let compareValue = date.toString().valueOf();

      let eintrag = this.zeiterfassung.find(
        (thisZE) =>
          parseDateString(thisZE.datum).toString().valueOf() === compareValue
      );

      return eintrag ? (eintrag[entry] ? eintrag[entry] : "") : "";
    }, */

    /* resetState() {
      this.state = {
        zeiterfassung: false,
        feiertage: false,
        account: false,
      };
    }, */

    loadOverview() {
      if (!this.mitarbeiter?.id) return;

      // this.resetState();
      this.getZeiterfassung();
      //  this.getFeiertage();
      this.getAccount();
    },

    getZeiterfassung() {
      Api.get("zeiterfassung/timesheet/", {
        params: this.queryParams,
      }).then((response) => {
        this.zeiterfassung = response.data;
        this.state.zeiterfassung = true;
      });
    },

    getFeiertage() {
      Api.get("zeiterfassung/feiertage/", {
        params: { mo: this.month, y: this.year },
      }).then((response) => {
        this.feiertage = response.data;
        this.state.feiertage = true;
      });
    },

    getAccount() {
      let now = new Date();
      now =
        now.getDate() + "." + (now.getMonth() + 1) + "." + now.getFullYear();
      Api.get("zeiterfassung/account/", {
        params: {
          date: now,
          ma: this.mitarbeiter?.id,
        },
      }).then((response) => {
        this.zeitkonto = response.data[0];
        this.state.account = true;
      });
    },

    abbrechen() {
      this.$router.push({ name: "dashboard" });
    },

    showDate(date) {
      this.month = date.monthIndex;
      this.year = date.year;
      this.loadOverview();
    },

    setMitarbeiter(person) {
      this.mitarbeiter = person;
      this.loadOverview();
    },

    /*  editTimereport(row) {
      let thisId = row.item.id
        ? row.item.id == ""
          ? null
          : row.item.id
        : null;

      if (thisId) {
        this.$router.push({
          name: "timesheet",
          params: { id: thisId },
        });
      } else {
        this.$router.push({
          name: "timesheet-neu",
          params: { datum: row.item.date },
        });
      }
      this.$bus.$emit("editTimeReport");
    }, */

    closeMonth() {
      this.$bus.$emit("openMessageBoxEnhanced", {
        text: this.$t("timereporting.shouldmonthbeclosed"),
      });
    },

    closeMonthPerform() {
      let json = Object.assign({}, this.queryParams);
      json.person = this.angemeldeterMitarbeiter.person.id;

      Api.put("zeiterfassung/action/", json, {
        params: { act: "closemonth" },
      })
        .then(() => {
          this.$notify({
            type: "success",
            title: this.$t("notification.actionsuccessful"),
            text: this.$t("notification.timesheetmonthclosesuccessful"),
          });

          this.getZeiterfassung();
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    stringToDate(input) {
      const nums = input.split("."); // inputformat: dd.MM.yyyy
      let dateString = `${nums[2]}-${nums[1]}-${nums[0]}`; // Format: yyyy-MM-dd
      return new Intl.DateTimeFormat("de-CH").format(new Date(dateString));
    },
    details(timesheet) {
      this.$router.push({
        name: "timesheet",
        params: {
          id: timesheet.id,
          datum: timesheet.date,
          fromRoute: "overview",
        },
      });
    },
  },
};
</script>

<style></style>
