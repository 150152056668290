<template>
  <div class="list">
    <notifications></notifications>
    <navbar :title="$t('dashboard.projectoverview')"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="$t('dashboard.projectoverview')"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <!-- Button-row über Table -->

            <div class="block br-t-l-0 w-100-p br-t-l-xl-0 mb-2">
              <div class="container-fluid">
                <div class="row d-xl-flex">
                  <div
                    class="col-xl-9"
                    style="
                      border-right: medium solid;
                      border-color: $$dark-blue;
                    "
                  >
                    <h4>{{ $t("global.filter") }}</h4>
                  </div>
                  <div class="col-xl-3">
                    <div class="container-fluid">
                      <!-- neu Button -->
                      <div>
                        <button
                          class="btn btn-success mr-2"
                          @click="oeffneNeuesZeitdingensModal"
                        >
                          <font-awesome-icon
                            icon="fa-regular fa-plus"
                            class="mr-2"
                          />{{ $t("global.new") }}
                        </button>
                        <button
                          class="btn btn-primary"
                          @click="oeffneProjektbearbeitungsModal"
                        >
                          <font-awesome-icon
                            icon="fa-regular fa-plus"
                            class="mr-2"
                          />{{ $t("global.edit") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row d-xl-flex">
                  <!-- Auswahl über Ansicht Projekte/Aufgaben oder Mitarbeiter-->
                  <div
                    class="col-xl-9"
                    style="
                      border-right: medium solid;
                      border-color: $$dark-blue;
                    "
                  >
                    <div class="container-fluid p-0">
                      <div class="w-22-p">
                        <div class="form-group">
                          <v-select
                            v-model.trim="filter.insightMode"
                            :options="insightModes"
                            :placeholder="$t('global.insightmode')"
                          >
                            <span slot="no-options">{{
                              $t("global.nocustomerfound")
                            }}</span>
                          </v-select>
                          <label>{{ $t("global.insightmode") }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3"></div>
                </div>

                <!-- Filter, wenn insightMode = Kunden/Projekte/Aufgaben -->
                <div class="row" v-if="filter.insightMode">
                  <div
                    class="col-xl-9"
                    style="border-right: medium solid; border-color: $dark-blue"
                  >
                    <div class="container-fluid">
                      <div class="row">
                        <font-awesome-icon
                          icon="fa-duotone fa-arrow-turn-down-right"
                          class="ml-1 mt-4"
                        />
                        <div
                          class="w-22-p ml-4"
                          v-if="filter.insightMode === 'Mitarbeiter'"
                        >
                          <div class="form-group">
                            <v-select
                              v-model.trim="filter.mitarbeiter"
                              :options="mitarbeiter"
                              label="employee"
                              :reduce="(ma) => ma.id"
                              :placeholder="$t('global.employee')"
                            >
                              <span slot="no-options">{{
                                $t("global.nocustomerfound")
                              }}</span>
                            </v-select>
                            <label>{{ $t("global.employee") }}</label>
                          </div>
                        </div>
                        <!-- Filter Kunde -->

                        <div class="w-22-p ml-4" v-if="filter.insightMode">
                          <div class="form-group">
                            <v-select
                              v-model.trim="filter.kunde"
                              :options="kunden"
                              label="company"
                              :reduce="(k) => k.companyId"
                              :placeholder="$t('global.customer')"
                              :disabled="
                                filter.insightMode === 'Mitarbeiter' &&
                                filter.mitarbeiter
                                  ? false
                                  : filter.insightMode ===
                                    'Kunden/Projekte/Aufgaben'
                                  ? false
                                  : true
                              "
                            >
                              <span slot="no-options">{{
                                $t("global.nocustomerfound")
                              }}</span>
                            </v-select>
                            <label>{{ $t("global.customer") }}</label>
                          </div>
                        </div>
                        <!-- Filter Projekt -->
                        <div class="w-22-p ml-4" v-if="filter.insightMode">
                          <div class="form-group">
                            <v-select
                              v-model.trim="filter.projekt"
                              :options="projekte"
                              label="project"
                              :reduce="(p) => p.projectId"
                              :placeholder="$t('global.project')"
                              :disabled="
                                filter.insightMode === 'Mitarbeiter' &&
                                filter.mitarbeiter
                                  ? false
                                  : filter.insightMode ===
                                    'Kunden/Projekte/Aufgaben'
                                  ? false
                                  : true
                              "
                            >
                              <span slot="no-options">{{
                                $t("global.nocustomerfound")
                              }}</span>
                            </v-select>
                            <label>{{ $t("global.project") }}</label>
                          </div>
                        </div>
                        <!-- Filter Projektaufgabe -->
                        <div class="w-22-p ml-4" v-if="filter.insightMode">
                          <div class="form-group">
                            <v-select
                              v-model.trim="filter.projektaufgabe"
                              :options="projektaufgaben"
                              label="projectTask"
                              :reduce="(pa) => pa.projectTaskId"
                              :placeholder="$t('global.projecttask')"
                              :disabled="
                                filter.insightMode === 'Mitarbeiter' ||
                                filter.kunde
                                  ? false
                                  : true
                              "
                            >
                              <span slot="no-options">{{
                                $t("global.nocustomerfound")
                              }}</span>
                            </v-select>
                            <label>{{ $t("global.projecttask") }}</label>
                          </div>
                        </div>
                        <!-- Filter Mitarbeiter -->
                        <div
                          class="w-22-p ml-4"
                          v-if="
                            filter.insightMode === 'Kunden/Projekte/Aufgaben'
                          "
                        >
                          <div class="form-group">
                            <v-select
                              v-model.trim="filter.mitarbeiter"
                              :options="mitarbeiter"
                              label="employee"
                              :reduce="(ma) => ma.id"
                              :placeholder="$t('global.employee')"
                              :disabled="filter.projektaufgabe ? false : true"
                            >
                              <span slot="no-options">{{
                                $t("global.nocustomerfound")
                              }}</span>
                            </v-select>
                            <label>{{ $t("global.employee") }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3"></div>
                </div>
              </div>
            </div>

            <!-- alle 5 Tabellen. Je nach Filtereinstellungen wird eine davon angezeigt. (Bisher wurde nur eine Component erstellt) -->
            <projekterfassungtableDefault
              v-if="tabellenbedingung(filter) === 'Standardansicht'"
              :id="tabellenId"
              :filter="filter"
              :kunden="kunden"
            />

            <projekterfassungtableKunde
              v-if="tabellenbedingung(filter) === 'Kundenansicht'"
              :id="tabellenId"
              :filter="filter"
            />

            <projekterfassungtableProjekt
              v-if="tabellenbedingung(filter) === 'Projektansicht'"
              :id="tabellenId"
              :filter="filter"
            />
            <!--  
            <projekterfassungtableAufgabe
               v-if="tabellenbedingung(filter) === 'Projektaufgabenansicht'"
              :id="tabellenId"
              :filter="filter"
            />

            <projekterfassungtableMitarbeiter
              v-if="tabellenbedingung(filter) === 'Mitarbeiteransicht'"
              :id="tabellenId"
              :filter="filter"
            /> -->
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="geschaeftspartner-loeschen-modal"
      :selectedIds="selectedIds"
      :multiple="true"
    />

    <loading-overlay v-show="loading"></loading-overlay>

    <messagebox-count
      :headerText="$t('global.personsdeleted')"
      id="count-delete-alert-msgbox"
      :ok="true"
      :cancel="false"
      :okText="$t('global.ok')"
      :cancelText="$t('global.cancel')"
    />

    <neues-projekt-erfassen-modal
      :kunden="kunden"
      :projekte="alleProjekte"
      :projektstati="projektstati"
      @abbrechen="abbrechen"
      @erfasseNeuesProjekt="erfasseNeuesProjekt"
      @editProjekt="editProjekt"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import HeadMenu from "@/components/HeadMenu";
/* import HeadMenuFilter from "@/components/HeadMenuFilter"; */
import { apiErrorToAlert } from "@/utils/Errorhandler";

import Navbar from "@/components/Navbar";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import NeuesProjektErfassenModal from "@/components/Modals/NeuesProjektErfassenModal";

import store from "@/store";

import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";

/* import DatePicker from "@/components/Datepicker"; */
import ProjekterfassungtableDefault from "@/pages/Zeiterfassung/ProjekterfassungtableDefault";
import ProjekterfassungtableKunde from "@/pages/Zeiterfassung/ProjekterfassungtableKunde";
import ProjekterfassungtableProjekt from "@/pages/Zeiterfassung/ProjekterfassungtableProjekt";
import { LADE_AUSWAHLWERTE_ZEITERFASSUNG } from "@/store/zeiterfassung/actions.type";

export default {
  name: "Projekterfassunguebersichtsliste",
  components: {
    Navbar,
    LoeschenModal,
    HeadMenu,
    //HeadMenuFilter,
    ProjekterfassungtableDefault,
    ProjekterfassungtableKunde,
    ProjekterfassungtableProjekt,
    // DatePicker,
    NeuesProjektErfassenModal,
  },
  mixins: [page, dashboard],
  store,

  data() {
    return {
      loading: false,
      tabellenId: "",
      insightModes: ["Kunden/Projekte/Aufgaben", "Mitarbeiter"],
      kunden: [],
      projekte: [],
      projektaufgaben: [],
      mitarbeiter: [],
      kundenAlt: [],
      projekteAlt: [],
      projektaufgabenAlt: [],
      mitarbeiterAlt: [],
      filter: {
        insightMode: "Kunden/Projekte/Aufgaben",
        kunde: null,
        projekt: null,
        projektaufgabe: null,
        mitarbeiter: null,
        startDatum: null,
        count: 50,
      },
      alleProjekte: [],
      //  modalUseCase: "",
    };
  },
  created() {
    this.tabellenbedingung(this.filter);
    this.getAll();
  },
  mounted() {
    if (this.projektstati.length == 0) {
      this.$store.dispatch(`zeiterfassung/${LADE_AUSWAHLWERTE_ZEITERFASSUNG}`);
    }
  },

  watch: {
    /* kann man vlt in den filter-Watcher einbauen, aber neuer und alter insightmode sind dort gleich */
    "filter.insightMode": function (newInsightMode, oldInsightMode) {
      if (newInsightMode !== oldInsightMode && newInsightMode !== null) {
        this.resetSubFilter();
      }
    },

    filter: {
      handler(neuerFilter) {
        this.tabellenbedingung(neuerFilter);
        console.log(this.tabellenbedingung(neuerFilter));
        if (neuerFilter.insightMode !== null) {
          this.getAll();
        }
      },
      deep: true,
    },

    /* 
    //Frontend-Filter (ein Versuch)

    "filter.kunde": function (neuerFilterKunde) {
      // neuerFilterKunde ist die FirmaID
      if (neuerFilterKunde) {
        this.projekte = this.projekteAlt.filter((item) => {
          return item.firmaId === neuerFilterKunde;
        });
      }
      if (neuerFilterKunde === null) {
        this.projekte = this.projekteAlt;
        this.resetSubFilter();
      }
    },
    "filter.projekt": function (neuerFilterProjekt) {
      // neuerFilterProjekt ist die ProjektID
      if (neuerFilterProjekt) {
        this.projektaufgaben = this.projektaufgabenAlt.filter((item) => {
          return item.projektId === neuerFilterProjekt;
        });
      }
      if (neuerFilterProjekt === null) {
        this.projektaufgaben = this.projektaufgabenAlt;
        this.filter.projektaufgabe = null;
      }
    },

    // macht noch nichts, da API Call noch keine Mitarbeiter schickt.
    "filter.projektaufgabe": function (neuerFilterProjektaufgabe) {
      // neuerFilterProjektaufgabe ist die ProjektaufgabenID
      if (neuerFilterProjektaufgabe) {
        this.mitarbeiter = this.mitarbeiterAlt.filter((item) => {
          return item.projektAufgabeId === neuerFilterProjektaufgabe;
        });
      }
      if (neuerFilterProjektaufgabe === null) {
        this.mitarbeiter = this.mitarbeiterAlt;
        this.filter.mitarbeiter = null;
      }
    }, */
  },
  computed: {
    projektstati: {
      get() {
        return this.$store.state.zeiterfassung.projektstati;
      },
    },
  },
  methods: {
    getAll() {
      Api.get("zeiterfassung/projektuebersicht_auswahlwerte/", {
        params: {
          insightMode: this.filter.insightMode,
          employeeId: this.filter.mitarbeiter,
          companyId: this.filter.kunde,
          projectId: this.filter.projekt,
          projectTaskId: this.filter.projektaufgabe,
        },
      }).then((response) => {
        // Hier alle Filteroptionen initialisieren
        // Bedingung, damit nicht neu initialisiert wird, wenn der betreffende Filter gewählt ist und der folgende Filter nicht. (alle auswahlmöglichkeiten sollten dann möglich sein)
        /* if (
          this.filter.kunde === null ||
          !(this.filter.kunde !== null && this.filter.projekt === null)
        ) {
          this.kunden = response.data.selectionCustomer;
          console.log("neue auswahlwerte für Kunde");
          console.log(this.kunden);
        } */
        this.kunden = response.data.selectionCustomer;
        // this.kundenAlt = response.data.auswahlwerteKunde;
        this.projekte = response.data.selectionProject;
        // this.projekteAlt = response.data.auswahlwerteProjekt;
        this.projektaufgaben = response.data.selectionProjectTask;
        //this.projektaufgabenAlt = response.data.auswahlwerteProjektaufgabe;
        this.mitarbeiter = response.data.selectionEmployee;
        console.log(this.mitarbeiter);
      });

      if (this.alleProjekte.length === 0) {
        Api.get("zeiterfassung/projektuebersicht_auswahlwerte/", {
          params: {
            fromModal: true,
          },
        }).then((response) => {
          this.alleProjekte = response.data.projekte;
        });
      }
    },
    tabellenbedingung(filter) {
      if (
        filter.insightMode !== null &&
        filter.insightMode === "Kunden/Projekte/Aufgaben"
      ) {
        // Bedingungen die true werden, je nachdem welche Filter ausgewählt sind (eindeutig für alle 5 Tabellen, nicht optimiert)
        // Standardfall
        if (
          !(
            filter.kunde ||
            filter.projekt ||
            filter.projektaufgabe ||
            filter.mitarbeiter
          )
        ) {
          return "Standardansicht";
        }
        // Kunde
        if (
          filter.kunde &&
          !(filter.projekt || filter.projektaufgabe || filter.mitarbeiter)
        ) {
          return "Kundenansicht";
        }
        // Kunde & Projekt
        if (
          filter.kunde &&
          filter.projekt &&
          !(filter.projektaufgabe || filter.mitarbeiter)
        ) {
          return "Projektansicht";
        }
        // Kunde & Projekt & Aufgabe
        if (
          filter.kunde &&
          filter.projekt &&
          filter.projektaufgabe &&
          !filter.mitarbeiter
        ) {
          return "Projektaufgabenansicht";
        }
        // Kunde & Projekt & Aufgabe & Mitarbeiter
        if (
          filter.kunde &&
          filter.projekt &&
          filter.projektaufgabe &&
          filter.mitarbeiter
        ) {
          return "Mitarbeiteransicht";
        }
      } else if (filter.insightMode === "Mitarbeiter") {
        if (
          !(
            filter.kunde ||
            filter.projekt ||
            filter.projektaufgabe ||
            filter.mitarbeiter
          )
        ) {
          return "MitarbeiterStandard";
        }
      }
      if (filter.insightMode === null) {
        return "InsightMode nicht definiert";
      } else return "Keine Bedingung trifft zu";
    },
    resetSubFilter() {
      // Wenn insightModus verändert wird, werden subfilter auf null gesetzt.
      this.filter.kunde = null;
      this.filter.projekt = null;
      this.filter.projektaufgabe = null;
      this.filter.mitarbeiter = null;
    },
    vervollstaendigen() {
      // wenn Projekt gesetzt wurde, soll filter.kunde automatisch gesetzt werden. Könnte man mit den Aufgaben auch machen, wenn diese eindeutig benannt sind.
    },
    oeffneNeuesZeitdingensModal() {
      // emit event, das im Modalcomponent das Öffnen bewirkt
      this.$bus.$emit("neues-projekt-erfassen");
    },
    oeffneProjektbearbeitungsModal() {
      this.$bus.$emit("bearbeite-projekt");
    },
    abbrechen() {
      // was geschieht, wenn im Modal abbrechen gedrückt wird.
    },
    erfasseNeuesProjekt(json) {
      // POST, welcher einen neuen Eintrag mit den Daten aus dem Modal erstellt.
      if (json) {
        Api.post("zeiterfassung/projektuebersicht/", json)
          .then(() => {
            this.$notify({
              type: "success",
              title: this.$t("notification.actionsuccessful"),
              text: this.$t("notification.projectsavedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          });
      }
    },
    editProjekt(json) {
      // POST, welcher einen neuen Eintrag mit den Daten aus dem Modal erstellt.
      console.log(
        "Hier soll ein PUT ausgeführt werden auf das gewählte Projekt oder eine Aufgabe."
      );
      /* if (json) {
        Api.put("zeiterfassung/projektuebersicht/", json).then((response) => {
          console.log(response);
        });
      } */
    },
  },
};
</script>

<style>
</style>