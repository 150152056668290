<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="
          $t('global.internaltime') + ' (' + interneerfassungenCount + ')'
        "
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success mr-2"
              @click="neueInterneErfassung"
              v-if="editable"
            >
              <font-awesome-icon icon="fa-regular fa-plus" class="mr-2" />
              {{ $t("global.newinternalentry") }}
            </button>
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="editable"
            >
              <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <div>
              <b-table
                ref="selectableTable"
                tbody-tr-class="item"
                style="max-height: 300px"
                responsive
                sticky-header
                :items="interneerfassungen"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :busy="isBusy"
                show-empty
                sort-icon-left
                selectable
                select-mode="range"
              >
                <template v-slot:head(selected)="row">
                  <b-form-checkbox
                    v-model="row.selected"
                    @input="selectAllRows(row)"
                  ></b-form-checkbox>
                </template>

                <template v-slot:cell(selected)="row">
                  <b-form-checkbox
                    v-model="row.item.selected"
                    @input="onRowSelected(row)"
                  ></b-form-checkbox>
                </template>

                <template #cell(edit)="row" class="d-flex align-items-center">
                  <div
                    class="ml-5"
                    role="button"
                    @click="interneerfassungBearbeiten(row.item)"
                  >
                    <font-awesome-icon icon="fa-regular fa-pencil" size="sm" />
                  </div>
                </template>

                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>

                <template #empty>
                  <div v-if="ladeFehler" class="text-center text-danger my-2">
                    <strong>{{ $t("global.errorwhileloading") }}</strong>
                  </div>
                  <div v-if="!ladeFehler" class="text-center text-primary my-2">
                    <strong>{{ $t("global.nodataavailable") }}</strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <interneerfassung-modal
      @abbrechen="getInterneerfassungen"
      :arbeitszeit="arbeitszeit"
      :rapportierteStunden="rapportierteStunden"
      :nichtrapportierteStunden="nichtrapportierteStunden"
      @uebernehmeInterneErfassung="interneErfassungUebernehmen"
    />

    <loeschen-modal
      id="interneerfassung-loeschen-modal"
      @confirmed="interneerfassungLoeschen"
      :selectedIds="selectedIds"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

import LoeschenModal from "@/components/Modals/LoeschenModal";
import InterneerfassungModal from "@/components/Modals/InterneerfassungModal.vue";

import reiter from "@/mixins/Reiter";

export default {
  name: "Anmeldungsliste",
  components: {
    ContainerHeadline,
    LoeschenModal,
    BSpinner,
    InterneerfassungModal,
  },
  mixins: [reiter],
  props: {
    timesheet: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    arbeitszeit: {
      type: Number,
      required: true,
    },
    rapportierteStunden: {
      type: Number,
      required: true,
      default: 0,
    },
    nichtrapportierteStunden: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 200,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      interneerfassungen: [],
      interneerfassungenCount: 0,
      aktuelleRapportierteStunden: 0,
      fields: [
        { key: "selected", label: "" },
        {
          key: "internercode.bezeichnung",
          sortable: true,
          label: this.$t("dashboard.internalcode"),
        },
        {
          key: "stunden",
          sortable: true,
          label: this.$t("dashboard.hours"),
        },
        { key: "edit", sortable: false, label: this.$t("dashboard.edit") },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {
    shown(val) {
      if (val && this.interneerfassungen.length == 0)
        this.getInterneerfassungen();
    },

    aktuelleRapportierteStunden(val) {
      this.$emit("updateRapportierteStunden", val);
    },
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  mounted() {
    if (this.shown && this.interneerfassungen.length == 0)
      this.getInterneerfassungen();
    this.setHeaders("dashboard", ["", "internalcode", "hours", "edit"]);
  },
  created() {},
  methods: {
    interneerfassungBearbeiten(projekterfassung) {
      let thisErfassung = {
        stunden: projekterfassung.stunden,
        timesheet: this.timesheet,
        id: projekterfassung.id,
        internercode: projekterfassung.internercode,
        bemerkung: projekterfassung.bemerkung,
        arbeitszeit: this.arbeitszeit,
        rapportierteStunden: this.rapportierteStunden,
      };

      this.$bus.$emit("interneerfassung-modal", {
        projekterfassung: thisErfassung,
        timesheet: this.timesheet,
      });
    },

    getInterneerfassungen() {
      this.isBusy = true;

      Api.get("zeiterfassung/projekterfassung/", {
        params: {
          ts: this.timesheet,
          type: 1,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.interneerfassungen = response.data;
          this.getAktuelleRapportierteStunden();
          this.interneerfassungenCount = this.interneerfassungen.length;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    neueInterneErfassung() {
      this.$bus.$emit("interneerfassung-modal", { timesheet: this.timesheet });
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "interneerfassung-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("notification.actionfailed"),
          text: this.$t("notification.noregistrationselecteddeletion"),
        });
      }
    },

    interneErfassungUebernehmen(json) {
      if (json.id) {
        Api.put("zeiterfassung/projekterfassung/", json, {
          params: { id: json.id },
        }).then(() => {
          this.getInterneerfassungen();
        });
      } else {
        Api.post("zeiterfassung/projekterfassung/", json).then(() => {
          this.getInterneerfassungen();
        });
      }
    },

    interneerfassungLoeschen() {
      this.isBusy = true;

      Api.delete("zeiterfassung/projekterfassung/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getInterneerfassungen();
      });
    },

    getAktuelleRapportierteStunden() {
      let sum = 0;
      this.interneerfassungen.forEach((erfassung) => {
        sum += erfassung.stunden;
      });
      this.aktuelleRapportierteStunden = sum;
    },
  },
};
</script>
