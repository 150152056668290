<template>
  <div>
    <container-headline
      :headline="$t('global.personaldata')"
      :col="6"
    ></container-headline>

    <div class="row mb-3">
      <div class="col-xl-12 block br-t-l-0">
        <div class="eingabe-panel-kopf p-3">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <!-- Felder für Gesch#ftspartner vom Typ Person -->
                <div class="col-xl-10">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <input
                            v-model.trim="person.name"
                            class="form-control"
                            v-bind:class="{ 'border-danger': !passed }"
                            placeholder=" "
                            :readonly="!editable"
                            :tabindex="1"
                          />
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                            $t("global.surname")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <input
                            v-model.trim="person.vorname"
                            class="form-control"
                            v-bind:class="{ 'border-danger': !passed }"
                            placeholder=" "
                            :readonly="!editable"
                            :tabindex="2"
                          />
                          <label v-bind:class="{ 'text-danger': !passed }">{{
                            $t("global.firstname")
                          }}</label>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <ValidationProvider
                          rules="required"
                          immediate
                          v-slot="{ passed }"
                        >
                          <v-select
                            v-if="partnerrollen"
                            v-model="person.rolle"
                            label="bezeichnung"
                            v-bind:class="{ 'border-info': !passed }"
                            :options="partnerrollen"
                            :disabled="!editable"
                            :placeholder="$t('dashboard.type')"
                            :reduce="(r) => r.id"
                            multiple
                            :tabindex="3"
                          >
                            <span slot="no-options">{{
                              $t("notification.taptosearch")
                            }}</span>
                          </v-select>
                          <label v-bind:class="{ 'text-danger': !passed }">
                            {{ $t("dashboard.type") }}</label
                          >
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="person.akademischertitel"
                          label="bezeichnung"
                          :options="akademischetitel"
                          :disabled="!editable"
                          :reduce="(a) => a.id"
                          :placeholder="$t('global.academictitle')"
                          :tabindex="4"
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.academictitle") }}</label>
                      </div>
                    </div>
                    <!--<div class="col-xl-4" v-if="this.roles.isStudent">
                      <div class="form-group">
                        <v-select
                          v-model="person.niveau"
                          label="bezeichnung"
                          :options="niveaus"
                          :disabled="!editable"
                          :reduce="(a) => a.id"
                          :placeholder="$t('global.niveau')"
                          :tabindex="2"
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.niveau") }}</label>
                      </div>
                    </div>-->
                    <div
                      class="col-xl-8"
                      v-if="
                        this.roles.isKunde ||
                        this.roles.isLieferant ||
                        this.roles.isTeilnehmerInfoabend ||
                        this.roles.isTraegerschaft ||
                        this.roles.isArbeitgeber
                      "
                    >
                      <div class="form-group">
                        <input
                          v-model.trim="person.funktion"
                          class="form-control"
                          :readonly="!editable"
                          placeholder=" "
                          :tabindex="5"
                        />
                        <label>{{ $t("global.function") }}</label>
                      </div>
                    </div>
                  </div>

                  <div v-if="$CONST('CONFIG').APPTYPE != 'DESKA'">
                    <div class="row">
                      <div class="col-xl-4">
                        <div class="form-group">
                          <date-picker
                            date-format="dd.mm.yy"
                            :initial="person.geburtsdatum"
                            placeholder=" "
                            :show-icon="true"
                            :disabled="!editable"
                            :tabindex="6"
                            @update="(val) => (person.geburtsdatum = val)"
                          />
                          <label>{{ $t("global.dateofbirth") }}</label>
                        </div>
                      </div>
                      <div class="col-xl-4">
                        <div class="form-group">
                          <ValidationProvider
                            rules="required"
                            immediate
                            v-slot="{ passed }"
                          >
                            <v-select
                              v-model="person.geschlecht"
                              label="bezeichnung"
                              v-bind:class="!passed ? 'border-danger' : ''"
                              :options="geschlechter"
                              :reduce="(g) => g.id"
                              :disabled="!editable"
                              :placeholder="$t('global.gender')"
                              :tabindex="7"
                            >
                              <span slot="no-options">{{
                                $t("notification.taptosearch")
                              }}</span>
                            </v-select>
                            <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.gender")
                            }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                      <div class="col-xl-4">
                        <div class="form-group">
                          <ValidationProvider
                            rules="required"
                            immediate
                            v-slot="{ passed }"
                          >
                            <v-select
                              v-model="person.anrede"
                              label="bezeichnung"
                              v-bind:class="!passed ? 'border-danger' : ''"
                              :options="anreden"
                              :disabled="!editable"
                              :reduce="(a) => a.id"
                              :placeholder="$t('global.salutation')"
                              :tabindex="8"
                            >
                              <span slot="no-options">{{
                                $t("notification.taptosearch")
                              }}</span>
                            </v-select>
                            <label v-bind:class="{ 'text-danger': !passed }">{{
                              $t("global.salutation")
                            }}</label>
                          </ValidationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row"></div>
                  <div class="row">
                    <div class="col-xl-4">
                      <div class="form-group">
                        <v-select
                          v-model="person.aktivitaetsstatus"
                          label="bezeichnung"
                          :options="aktivitaetsstati"
                          :reduce="(a) => a.id"
                          :disabled="!editable && !isAdmin"
                          :placeholder="$t('global.status')"
                          :tabindex="9"
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.status") }}</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-4"
                      v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                    >
                      <div
                        v-if="
                          roles.isDozent ||
                          roles.isMitarbeiter ||
                          roles.isDiplomarbeiten ||
                          roles.isReferent
                        "
                        class="form-group"
                      >
                        <v-select
                          v-model="person.zivilstand"
                          label="bezeichnung"
                          :options="zivilstaende"
                          :reduce="(z) => z.id"
                          :disabled="!editable"
                          :placeholder="$t('global.civilstatus')"
                          :tabindex="10"
                        >
                          <span slot="no-options">{{
                            $t("notification.taptosearch")
                          }}</span>
                        </v-select>
                        <label>{{ $t("global.civilstatus") }}</label>
                      </div>
                    </div>
                    <div
                      class="col-xl-4"
                      v-if="$CONST('CONFIG').APPTYPE === 'Education'"
                    >
                      <div
                        v-if="
                          roles.isDozent ||
                          roles.isMitarbeiter ||
                          roles.isDiplomarbeiten ||
                          roles.isReferent
                        "
                        class="form-group"
                      >
                        <date-picker
                          date-format="dd.mm.yy"
                          :initial="person.zivilstand_datum"
                          :disabled="!editable"
                          :show-icon="true"
                          :tabindex="11"
                          @update="(val) => (person.zivilstand_datum = val)"
                        />
                        <label>{{ $t("global.dateofcivilstatus") }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Ende Felder für Gesch#ftspartner vom Typ Person -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dropzoneOptionsBild } from "@/config/Dropzone";
import ContainerHeadline from "@/components/ContainerHeadline";

import DatePicker from "@/components/Datepicker";

import Api from "@/Api";

export default {
  name: "ZeiterfassungZeitkontoangaben", // Dieses File wurde aufgrund der Vorlage von Personalangaben.vue erstellt
  components: {
    ContainerHeadline,
    DatePicker,
  },
  mixins: [],
  props: {
    id: {
      type: String,
      required: false,
    },
    person: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editable: {
      type: Boolean,
      required: true,
    },
    roles: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      dropzoneOptions: dropzoneOptionsBild(Api.defaults.baseURL, this.id),
      isHidden: true,
      key: 0,
      bildKey: 0,
      isAdmin: false,
      firmenListe: [],
      relationstypen: [],
    };
  },
  computed: {
    partnerrollen: {
      get() {
        return this.$store.state.geschaeftspartner.partnerrollen;
      },
    },
    geschlechter: {
      get() {
        return this.$store.state.geschaeftspartner.geschlechter;
      },
    },
    anreden: {
      get() {
        return this.$store.state.geschaeftspartner.anreden;
      },
    },
    akademischetitel: {
      get() {
        return this.$store.state.geschaeftspartner.akademischetitel;
      },
    },
    aktivitaetsstati: {
      get() {
        return this.$store.state.geschaeftspartner.aktivitaetsstati;
      },
    },
    zivilstaende: {
      get() {
        return this.$store.state.geschaeftspartner.zivilstaende;
      },
    },
    /*niveaus: {
      get() {
        return this.$store.state.geschaeftspartner.kompetenzstufen;
      },
    },*/
  },
  created() {
    if (this.firmenListe.length == 0) this.ladeFirmen();
    if (this.relationstypen.length == 0) this.ladeRelationstyp();
  },
  watch: {
    ["person.rolle"]: {
      handler: function (val) {
        if (val && this.partnerrollen.length > 0) {
          this.$emit("rolesChanged", val);
        }
      },
      immediate: true,
    },
  },
  mounted: function () {
    /*if (this.partnerrollen.length == 0)
      this.$store.dispatch(
        `geschaeftspartner/${LADE_AUSWAHLWERTE_GESCHAEFTSPARTNER}`
      );
*/
    this.isAdmin =
      this.gruppen.findIndex(
        (gruppe) => gruppe.id == this.$CONST("GRUPPEN").ADMIN
      ) == -1
        ? false
        : true;
  },
  methods: {
    ladeGeschaeftspartner() {
      this.$emit("bildUpdated", this.id);
    },

    oeffneDokumentModal() {
      $("#bild-modal").modal("show");
    },

    fotoLoeschen() {
      Api.delete("bildupload/", { params: { id: this.person.id } }).then(() => {
        this.$emit("bildUpdated");
      });
    },
    ladeFirmen() {
      /*this.loading = true;

      //var params = null;

      Api.get("/geschaeftspartner/", {
        params: { typ: "17EF88469AE" },
      })
        .then((response) => {
          this.firmenListe = [];
          response.data.forEach((geschaeftspartner) => {
            this.firmenListe.push({
              id: geschaeftspartner.id,
              firmaid: geschaeftspartner.firma.id,
              name: geschaeftspartner.firma.name,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });*/
    },
    ladeRelationstyp() {
      /*this.loading = true;

      var params = null;

      Api.get("/geschaeftspartner/relationstyp/", {
        params: params,
      })
        .then((response) => {
          this.relationstypen = [];
          response.data.forEach((relationstyp) => {
            this.relationstypen.push({
              id: relationstyp.id,
              bezeichnung: relationstyp.bezeichnung,
              style: "font-size: 0.8em; cursor: pointer",
            });
          });
        })
        .finally(() => {
          this.loading = false;
        });*/
    },
    relationLoeschen(relation) {
      Api.delete("geschaeftspartner/relation/", {
        data: [this.id],
        params: { id: relation },
      }).then(() => {
        this.$router.push({ name: "GeschaeftspartnerListe" });
      });
    },
  },
};
</script>

<style>
</style>

